'use strict';

(function () {
  class AddEditBladeModalCtrl {
    constructor($scope, $uibModalInstance, Turbine, Blade, options, usSpinnerService, Util, ActivityLog, Auth) {
      this.Util = Util;
      this.Blade = Blade;
      this.Turbine = Turbine;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.components = [];
      this.popup1 = {
        opened: false
      };
      this.popup2 = {
        opened: false
      };
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        }
      });
      console.log(this.options.turbine.blades);
      this.blade = {
        turbine: this.options.turbine._id,
        serial: this.options.blade ? this.options.blade.serial : null,
        setNumber: this.options.blade ? this.options.blade.setNumber : null,
        color: this.options.blade ? this.options.blade.color : this.options.turbine.blades[this.options.turbine.blades.length-1] ? this.options.turbine.blades[this.options.turbine.blades.length-1].color :  null,
        make: this.options.blade ? this.options.blade.make : this.options.turbine.blades[this.options.turbine.blades.length-1] ? this.options.turbine.blades[this.options.turbine.blades.length-1].make :  null,
        model: this.options.blade ? this.options.blade.model : this.options.turbine.blades[this.options.turbine.blades.length-1] ? this.options.turbine.blades[this.options.turbine.blades.length-1].model :  null,
        position: this.options.blade ? this.options.blade.position : null,
        material: this.options.blade ? this.options.blade.material : this.options.turbine.blades[this.options.turbine.blades.length-1] ? this.options.turbine.blades[this.options.turbine.blades.length-1].material :  null,
        diameter: this.options.blade ? this.options.blade.diameter : null,
        surface: this.options.blade ? this.options.blade.surface : null,
        length: this.options.blade ? this.options.blade.length : this.options.turbine.blades[this.options.turbine.blades.length-1] ? this.options.turbine.blades[this.options.turbine.blades.length-1].length :  null,
        condition: this.options.blade ? this.options.blade.condition : null
      };

      if (this.options.blade && this.options.blade._id) {
        this.blade._id = this.options.blade._id;
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    /*Returns length of existing blades*/
    bladeExists(bladeNumber) {
      return this.options.turbine.blades.filter(blade => blade.position === bladeNumber).length;
    }

    saveBlade(form) {
      let dropDown = document.getElementById("reset");
      dropDown.selectedIndex = "";
      this.submitted = true;
      this.serverError = null;
      form['position'].$setValidity('mongoose', true);
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        if (this.blade._id) {
          this.Blade.update(this.blade).$promise
            .then(blade => {
              this.stopSpin('spinner-1');
              console.log(blade);

              let turbineStr = '';
              if(this.options.turbine.make) {
                turbineStr+=this.options.turbine.make
              }
              if(this.options.turbine.model) {
                turbineStr+=this.options.turbine.model
              }
              if(this.options.turbine.name) {
                turbineStr+=this.options.turbine.name
              }

              let bladeStr = '';
              if(blade.data.make) {
                bladeStr+=blade.data.make+' ';
              }
              if(blade.data.model) {
                bladeStr+=blade.data.model;
              }
              if(blade.data.name) {
                bladeStr+=blade.data.name;
              }
              const activityLogData = {
                site: this.options.turbine.site._id,
                turbine: this.options.turbine._id,
                blade: blade.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'edit',
                type: 'blade',
                sourceScreen: 'blade',
                eventString: `Blade <a href="/blade/${blade.data._id}">${bladeStr}</a> was edited in turbine <a href="/turbine/${this.options.turbine._id}">${turbineStr}</a>, wind farm <a href="/site/${this.options.turbine.site._id}">${this.options.turbine.site.name}</a> by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.isDisabled = false;
              //this.$uibModalInstance.close(blade);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          this.Blade.save(this.blade).$promise
            .then(blade => {
              this.stopSpin('spinner-1');
              console.log(blade);

              let turbineStr = '';
              if(this.options.turbine.make) {
                turbineStr+=this.options.turbine.make
              }
              if(this.options.turbine.model) {
                turbineStr+=this.options.turbine.model
              }
              if(this.options.turbine.name) {
                turbineStr+=this.options.turbine.name
              }

              let bladeStr = '';
              if(blade.data.make) {
                bladeStr+=blade.data.make+' ';
              }
              if(blade.data.model) {
                bladeStr+=blade.data.model;
              }
              if(blade.data.name) {
                bladeStr+=blade.data.name;
              }
              const activityLogData = {
                site: this.options.turbine.site._id,
                turbine: this.options.turbine._id,
                blade: blade.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'create',
                type: 'blade',
                sourceScreen: 'turbine',
                eventString: `Blade <a href="/blade/${blade.data._id}">${bladeStr}</a> was created in turbine <a href="/turbine/${this.options.turbine._id}">${turbineStr}</a>, wind farm <a href="/site/${this.options.turbine.site._id}">${this.options.turbine.site.name}</a> by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.isDisabled = false;
              //this.$uibModalInstance.close(blade);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log(err);
      }
    }

    openManufacturedDate() {
      this.popup1.opened = true;
    }

    openCommisioningDate() {
      this.popup2.opened = true;
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditBladeModalCtrl', AddEditBladeModalCtrl);
})();

